<template>
  <div class="menu-wrapper">
    <div class="wrap">
      <!-- 左侧菜单 -->
      <div class="menu-wrap">
        <el-menu
          :default-active="this.$route.path"
          class="el-menu-vertical-demo"
          :unique-opened="true"
          :collapse-transition="false"
          @open="handleOpen"
          @close="handleClose"
          router
        >
          <el-submenu
            v-for="(item, index) in menus"
            :key="index"
            :index="index + ''"
          >
            <template slot="title" >
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(menu, menuIndex) in item.list"
                :key="menuIndex"
                :index="menu.linkUrl"
                >{{ menu.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <!-- <div class="menu-group" v-for="(item, index) in menus" :key="index">
					<div class="title">{{item.title}}</div>
					<div class="menu-list">
						<block v-for="(menu, menuIndex) in item.list" :key="menuIndex">
							<navigator :class="['menu', {'active': isActive(index, menuIndex)}]" :url="menu.linkUrl">{{menu.name}}</navigator>
						</block>
					</div>
				</div> -->
      </div>
      <!-- 右侧内容 -->
      <div class="main-wrap">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import menus from "@/common/config/user/left-menus.config.js";

export default {
  props: {
    activeIndex: {
      type: String,
      default:''
    },
  },
  data() {
    return {
      menus,
      path: '',
    };
  },
  watch:{
    // $route:{
    //   handler (val, oldVal) {
    //     // this.oldUser = oldVal || val
    //     // console.log(val,oldVal,111)
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    // isActive(index, menuIndex) {
    // 	return index == this.indexArray[0] && menuIndex == this.indexArray[1];
    // }
    handleOpen(key, keyPath) {
      console.log(key, keyPath,11);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath,2);
      //  this.defaultOpenedsArray=[]
    },
  },
};
</script>

<style lang="scss">
.menu-wrapper {
  //   padding-bottom: 40px;
  //   background-color: $bg-color-grey;

  .wrap {
    width: $page-width;
    margin: 0 auto;
    display: flex;
		min-height: $main-min-height;

    .menu-wrap {
      width: 234px;
      padding: 36px 0;
      background-color: $bg-color;
      border-right: solid 1px #e6e6e6;
      font-weight: bold;
      .menu-group {
        margin: 0 48px 12px;

        .title {
          font-size: 16px;
          font-weight: 400;
          line-height: 52px;
          color: #333;
        }

        .menu-list {
          .menu {
            padding: 6px 0;
            color: #757575;
            cursor: pointer;

            &:hover {
              color: $text-color;
            }

            &.active {
              color: $color-primary;
            }
          }
        }
      }
      .el-menu-vertical-demo{
        border-right:none
      }
       .el-submenu {
         .el-menu-item{
            font-weight:normal;
         }
      }
    }

    .main-wrap {
      flex: 1;
      margin-left: 14px;
      background-color: $bg-color;
      padding: 36px 48px;
    }
  }
}

.el-menu-item.is-active{
  color:var(--ThemeColor,#ff6701);
}
</style>
