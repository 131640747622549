<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      
        <div class="secondTitle">我的留言</div>

      <div
        class="feedback-collapse"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        v-if="messageList.length > 0 || loading"
        v-loading="loading"
      >
      <scrollbar ref="scrollbarRef" height="calc(100vh - 420px)" class="scroll-container">
        <div style="margin-right:20px;">
          <div
            v-for="(item, index) in messageList"
            :key="index"
          >
            <el-card class="feedback-list">
              <span class="feedback-content">{{ item.title }}</span>
              <div />
              <span class="reply-content right"
                >提交于 {{ item.time | dateFormat }}
              </span>
              <div />
              <el-timeline :reverse="false">
              <el-timeline-item
                v-for="(repItem, repIndex) in item.replyContent"
                :key="repIndex"
                :timestamp=" repItem.replyTime | dateFormat" placement="top"
                :type="timelineType(item.replyContent,repIndex)"
                :icon="repItem.type == 0?'el-icon-user':'el-icon-message'"
                size="large"
                :class="{'myreply-context': repItem.type != 0 }"
              >
                <span :class="['reply-content', { user: repItem.type == 0 }]"
                  >{{ repItem.type == 0 ? "客服" : "我" }}：
                  {{ repItem.content }}
                  <!-- <span class="reply-content-time">[{{ repItem.replyTime | dateFormat }}]</span> -->              
                </span>
              </el-timeline-item>
              </el-timeline>
              <div class="feedback-reply" v-if="item.messageState == 0">
                <el-button
                  class="feedback-reply-btn"
                  size="mini"
                  @click="replyClick(index)"
                  round
                  :disabled="isLimitReply">
                  回复
                  </el-button
                >
              </div>
              
            </el-card>
          </div>
        </div>
        <p v-if="collapseLoading">加载中...</p>
        <p v-if="noMore && messageList.length > pageSize">没有更多了~</p>
        </scrollbar>
      </div>
      <el-empty v-else :description="description"></el-empty>
      <div class="feedback-mesg" v-if="feedbackState">
        <div class="textarea">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="抒善语，结善缘；弹指间，心无间。"
            v-model="textarea"
            maxlength="500"
            :show-word-limit="true"
          >
          </el-input>
        </div>
        <div class="btn">
          <el-button icon="el-icon-s-promotion" @click="sendMsg" :disabled="isLimitMsg">发送留言</el-button
          >
        </div>
      </div>
    </menu-wrapper>
    <div>
      <el-dialog
        title="回复客服"
        :visible.sync="dialogVisible"
        width="30%"
        center
      >
        <el-input
          type="textarea"
          :rows="5"
          placeholder="抒善语，结善缘；弹指间，心无间。"
          v-model="replyContent"
          maxlength="500"
          :show-word-limit="true"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="sendReplyContent">
            发 送
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      activeNames: [0], //下拉展开id
      textarea: "", //留言内容
      replyContent: "", //回复反馈内容
      replyIndex: 0, //回复反馈下标
      dialogVisible: false, //回复弹窗是否展示
      collapseLoading: false, //下拉列表loading
      pageSize: 15, //每页条数
      pageIndex: 1, //页码
      itemCount: 0, //总记录数
      loading: true, //留言列表oading
      noEndMsgNum: 0, //未结束会话
      messageList: [], //留言列表
      feedbackState:true,//功能是否关闭
      description:'暂无数据',
      isLimitMsg:true,
      isLimitReply:true,
    };
  },
  watch:{
    feedbackState(e){
      if(!e){
         let msg="客服已关闭该功能，如需继续使用请您联系客服"
        this.notifyError(msg);
        this.description=msg
      }
    }
  },
  computed: {
    noMore() {
      return this.messageList.length >= this.itemCount ;
    },
    disabled() {
      return this.collapseLoading || this.noMore;
    },
    timelineType(){
      return (val,index)=>{
        let type=''
        if(index==val.length-1){
          let data=val[index]
            if(data.type == 0){
              type='danger'
            }
            else{
              type="primary"
            }
        }
        return type
      }
    }
  },
  activated() {
    this.messageList = [];
    this.pageIndex = 1;
    this.noEndMsgNum = 0;
    this.getList();
  },
  methods: {
    //获取列表
    getList() {
      this.loading = true;
      this.$axios
        .get("/api/UserFeedback/GetList", {
          params: {
            UserID: this.$store.getters.getUserId,
            PageSize: this.pageSize,
            PageIndex: this.pageIndex,
          },
        })
        .then((res) => {
          if (res) {
            this.itemCount = res.itemCount;
            if (res.data) {
              this.noEndMsgNum = res.data.noEndMsgNum;
              if( typeof(res.data.feedbackState) !='undefined'){
                 this.feedbackState= res.data.feedbackState
              }
              this.isLimitMsg= res.data.isLimitMsg;
              this.isLimitReply= res.data.isLimitReply;
              var list = res.data.list;
              for (const item of list) {
                let mode = {
                  title: item.Content,
                  messageState: item.IsMsgEnd,
                  replyContent: [],
                  id: item.Id,
                  time: item.AddTime,
                };
                for (const items of item.ReplyList) {
                  let replyList = {
                    id: items.Id,
                    content: items.ReplyContent,
                    type: items.ReplyType,
                    replyTime: items.ReplyTime,
                  };
                  mode.replyContent.push(replyList);
                }
                this.messageList.push(mode);
              }
            }
          }
          this.loading = false;
        });
    },
    //下拉加载下一页
    load() {
      //防止重复加载
      if(this.noMore){
        this.pageIndex++;
        this.getList();
      }
    },
    handleChange(val) {
      console.log(val);
    },
    //点击回复弹窗
    replyClick(val) {
      this.replyContent = "";
      this.replyIndex = val;
      this.dialogVisible = true;
    },
    //发送回复
    sendReplyContent() {
      if (!this.replyContent) {
        this.notifyError("请输入回复内容");
        return;
      }
      let message = this.messageList[this.replyIndex];
      let replyContentList = message.replyContent;

      const data = {
        ReplyId: message.id,
        ReplyContent: this.replyContent,
      };
      this.loading = true;
      this.$axios
        .post("/api/UserFeedback/AddReply", data)
        .then((res) => {
          if (res.data) {
            const replyContent = {
              id: res.data,
              content: this.replyContent,
              type: 1,
              replyTime: Date.now(),
            };
            replyContentList.push(replyContent);
            this.dialogVisible = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return Promise.reject(err);
        });
      //var maxIndex = Math.max(...replyContentList);
    },
    sendMsg() {
      if (this.noEndMsgNum >= 3) {
        this.notifyError(
          "最多只能存在3条未结束的会话，请您刷新页面后重试~"
        );
        return;
      }
      if (!this.textarea) {
        this.notifyError("请输入留言内容");
        return;
      }
      const data = {
        UserId: this.$store.getters.getUserId,
        Content: this.textarea,
      };
      this.loading = true;
      this.$axios
        .post("/api/UserFeedback/Add", data)
        .then((res) => {
          if (res.data) {
            const message = {
              title: this.textarea,
              id: res.data,
              replyContent: [],
              messageState: false,
              time: Date.now(),
            };
            this.messageList.unshift(message);
            this.textarea = "";
            this.noEndMsgNum++;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-collapse {
  border-top: none;
}

::v-deep .el-collapse-item__header {
  font-size: 20px;
}

::v-deep .el-collapse-item__content {
  font-size: 15px;
  color: $text-color-grey;
}

.feedback-collapse {
  // height: 80%;
  // max-height: calc(100vh - 420px);
  // overflow: auto;

  & p {
    text-align: center;
    margin: 10px 0px;
  }

  .feedback-list {
    line-height: 30px;
    margin-bottom: 40px;
    .feedback-content {
      font-size: 15px;
      font-weight: bold;
    }

    .reply-content {
      font-size: 15px;
      // color: #999;
      &.user {
        color: $color-primary;
      }

      &.right {
        text-align: right;
        display: block;
        // color: #000;
        // font-size: 13px;
        margin-bottom:20px;
      }
      .reply-content-time{
        margin-left:20px;
      }
    }

    .feedback-reply {
      text-align: center;

      .feedback-reply-btn {
        cursor: pointer;
        // border: 1px solid $border-color;
        // border-radius: 3px;
        // padding: 0px 10px;
        // background: $border-color;
        // &:hover {
        //   //color: $text-color;
        //   // text-decoration: underline;
        //   background: $text-color-inverse;
        // }
      }
    }
    & .myreply-context {
      text-align: right; 
    }
    ::v-deep .el-timeline-item__timestamp{
      &.is-top{
        margin-bottom:0px;
      }
    }
  }
}

.feedback-mesg {
  margin-top: 20px;

  .btn {
    text-align: center;
    margin-top: 10px;
  }
}

.el-button--default{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
    color: white;
}
.el-button--default:hover{
  opacity: 0.8;
}
.el-button--primary{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--primary:hover{
  opacity: 0.8;
}

</style>